.flux-automatic-filter-selector-toggle {
  background-color: rgb(244, 244, 244) !important;
  border-color: #C1C1C1 !important;
  color: var(--bs-primary) !important;

  &:hover {
    background-color: rgb(222, 222, 222);
    border-color: #C1C1C1;
    color: var(--bs-primary);
  }

  &::after {
    margin-left: 2rem;
  }
}

.flux-automatic-filter-selector-menu {
  border-radius: 0;
  max-height: 70vh;
  overflow: scroll;
}

.flux-automatic-filter-selector-item {
  text-align: center;
  font-weight: 600;
  color: var(--bs-primary);

  &.active {
    background-color: var(--bs-primary);
    color: white;
  }

  &:hover, &:focus {
    color: var(--bs-primary);
  }

  &:active {
    background-color: inherit;
  }
}