.monitoring-option-container {
  &:active {
    background-color: var(--bs-dropdown-link-hover-bg);
  }
}

.monitoring-option {
  text-decoration: none;
  font-weight: bold;
  color: #212F58;

  &:hover, &:active {
    color: #212F58 !important;
  }
}