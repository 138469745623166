.list-container {
  padding: 28px 23px;
}

.special-header {
  color: #212F58;
  font-weight: 700;
}

.list-study-row {
  background-color: white;
  padding-bottom: 12px;
}

%description {
  font-weight: 600;
}

%first-line {
  margin-top: 8px;
}

.study-name {
  @extend %description;
  @extend %first-line;
  margin-bottom: 4px;
  font-size: 20px;
}

.study-description{
  @extend  %description;
}

.horizon {
  @extend %first-line;
  font-size: 16px;
}

.study-status {
  @extend %first-line;
}

.study-buttons {
  background-color: var(--bs-primary);
  color: white;
}
