.tabbed-page {
  .nav-link {
    font-weight: 600 !important;
    text-transform: uppercase !important;

    background-color: #598cbb !important;
    color: white !important;
    border: none !important;
  
    &.active {
      background-color: #f4f4f4 !important;
      color: #212f58 !important;
    }
  }

  .tabbed-page-content {
    min-height: 90vh;
    background-color: #f4f4f4;
    padding: 0.05px;
  }
}