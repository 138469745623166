.zoom-container {
  position: absolute;
  z-index: 1;
  left: 37.56%;
  bottom: 1.9rem;
  padding: 0px 6px;
  border-radius: 0.10188rem;
  background: #FFF;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.zoom-button {
  padding: 6px 6px;
	--bs-btn-color: #598CBB;
	--bs-btn-bg: transparent;
	--bs-btn-border-color: transparent;
	--bs-btn-hover-color: #729dc5;
	--bs-btn-hover-bg: transparent;
	--bs-btn-hover-border-color: transparent;
	--bs-btn-active-color: #7aa3c9;
	--bs-btn-active-bg: transparent;
	--bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #C1C1C1;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: transparent;
}
