.default-modal {
  width: 40vw;
  max-width: 40vw;
  --bs-modal-border-radius: 0;
}

.default-modal-title {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 31px;
  color: var(--bs-dark);
}

.default-modal .form-control,.btn {
  border-radius: 1.6px;
}

.responsive-modal {
  width: 60vw;
  max-width: 60vw;
}

@media (max-width: 1200px) {
  .responsive-modal {
    width: 80vw;
    max-width: 80vw;
  }
}
