.primary-button {
  border-radius: 0px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  --bs-btn-hover-bg: #FFFFFF !important;
  --bs-btn-hover-color: var(--bs-primary) !important;
  --bs-btn-hover-border-color: var(--bs-primary) !important;
}

.secondary-button {
  border-radius: 0px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  --bs-btn-bg: #FFFFFF00 !important;
  --bs-btn-color: var(--bs-primary) !important;
  --bs-btn-border-color: #FFFFFF00 !important;
  --bs-btn-hover-bg: #FFFFFF00 !important;
  --bs-btn-hover-color: var(--bs-primary) !important;
  --bs-btn-hover-border-color: #FFFFFF00 !important;
  --bs-btn-disabled-color: #C1C1C1 !important;
  --bs-btn-disabled-bg: #FFFFFF00 !important;
  --bs-btn-disabled-border-color: #FFFFFF00 !important;
}

.secondary-button:hover {
  text-decoration: underline;
}

.alert-light {
  --bs-alert-color: #3d5662 !important;
  --bs-alert-bg: #eaf7fd !important;
  --bs-alert-border-color: #e0f3fc !important;
}

.text-justified {
  text-align: justify !important;
}
