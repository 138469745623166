.flux-about-tab-container {
  .tab-content {
    min-height: 90vh;
    background-color: #f4f4f4;
  }
}

.flux-about-pdf {
  width: 100%;
  height: 85vh;
}
