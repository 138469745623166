.scenarios-container {
  background-color: white;
  min-height: 100%;
  margin: 2rem;
  margin-bottom: 1rem;
  padding: 2rem;

  > .btn {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
