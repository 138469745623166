.card-element {
  padding: 0 48px;
}
.popular-comparison {
  font-size: small;
  display: flex;
  width: 90%;
  margin: 18px auto;
  border-radius: 2px;
  background-color: #598CBB;
  color: white;
  justify-content: center;
  padding: 10px; 
}
