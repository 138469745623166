$track-color: #26548A;
$inner-track-color: var(--bs-secondary);

.synoptic-charts-range-control {
  display: flex;
  cursor: default;

  &.deselected {
    .synoptic-charts-thumb, .synoptic-charts-slider-control-button {
      color: #C1C1C1 !important;
      border-color: #C1C1C1 !important;
    }

    .synoptic-charts-track {
      background-color: #C1C1C1 !important;
    }

    .synoptic-charts-thumb-label {
      visibility: hidden;
    }
  }
}

.synoptic-charts-horizontal-slider {
  width: 90%;
  margin-top: 15px;
  margin-left: 5%;
  margin-right: 5%;
  height: 8px;
}

.synoptic-charts-thumb {
  top: -7.5px;
  padding-bottom: 22.5px;
  height: 25px;
  width: 25px;
  cursor: pointer;
  position: absolute;
  z-index: 0 !important;
  background: #ffffff;
  border: 3px solid $track-color;
  border-radius: 100%;
  display: block;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
}

.synoptic-charts-thumb-label {
  position: absolute;
  font-size: 14px;
  top: -25px;
  left: -38px;
  width: 100px;
  text-align: center;
}

.synoptic-charts-track {
  cursor: pointer;
  top: 0;
  bottom: 0;
  background: $track-color;
  border-radius: 999px;
}

.synoptic-charts-slider-control-button {
  color: $track-color;
}