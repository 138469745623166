.scenario-name {
  .form-label {
    color: #212F58;
    font-weight: 600;
  }

  .form-control {
    border-radius: .1rem;
  }
}

.scenario-blocks {
  div {
    margin-left: .25rem;
    margin-right: .25rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .flux-personalized-block {
    margin-bottom: 0;
  }
}

.new-block-button {
  border-radius: .1rem;
  border: 1px dashed #C1C1C1;
  min-height: 99px;
  height: 100%;
  --bs-btn-color: #585858;
  --bs-btn-bg: #fff;
  --bs-btn-border-color: #C1C1C1;
  --bs-btn-hover-color: #585858;
  --bs-btn-hover-bg: #F4F4F4;
  --bs-btn-hover-border-color: #F4F4F4;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #585858;
  --bs-btn-active-border-color: #585858;
}

.block-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
