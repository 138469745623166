.image-modal {
	.modal-dialog {
		max-width: 100%;

		.modal-content {
			display: flex;
			flex-direction: column;
			height: 90vh;
			width: fit-content;
			margin-left: auto;
			margin-right: auto;
			border-radius: 0;
		}
	}

	.btn-lg {
		font-size: 2.5rem;
	}

	.image-container {
		flex: 1 1 0;
		min-height: 0;
		min-width: 0;
		justify-content: center;
	}

	.image {
		object-fit: contain;
		height: 100%;
	}
}
