.video-action-button {
  padding: 0;
  color: white;
  font-size: 28px;
  line-height: 28px;
  margin-top: 12px;
  margin-bottom: 12px;
  margin-right: 8px;
  background-color: var(--bs-primary);
}
