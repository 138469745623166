.meteorology-video-back {
  color: var(--bs-secondary);
  font-size: 1.125rem;
  font-weight: 600;
  text-decoration: none;
}

.bulletin-download {
  color: var(--bs-primary);
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
  text-decoration: none;
}

.bulletin-download-button {
  padding: 0px 5px;
}

.bulletin-download-loading {
  padding: 0px 4px;
}

.meteorology-video-container {
  padding: 56.25% 0 0 0;
  position: relative;
}

.meteorology-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.meteorology-video-date {
  color: #C1C1C1;
  font-weight: 700;
}

.meteorology-video-title {
  color: var(--bs-primary);
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
}
