.report-form-header {
  color: #212F58;
  background-color: #F4F4F4;

  &.danger {
    color: white;
    background-color: #CA5959;
  }
}

.tabbed-page:has(.report-form-header.danger) {
  .nav-link.active {
    background-color: #CA5959 !important;
    color: white !important;
  }
}

.report-form-date-picker {
  padding: 0.4rem 1rem;
  border: 1px solid #ced4da;
}

.report-form-valid-border {
  border-color: #198754;
}

#report-cancellation-button {
  color: white;
}
