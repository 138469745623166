.mailer-report-row {
    --bs-accordion-bg: #f4f4f4;
    padding-left: 0px;

    .accordion-button {
      &:not(.collapsed) {
        background-color: #ECECEC;
        box-shadow: 0px 0px;
      }
    }

    .accordion-body {
        background-color: #ECECEC;
        margin-top: .25rem;
        padding-left: 0;
        padding-right: 0;
    }
}
