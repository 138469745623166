.filter-modal {
  --bs-modal-border-radius: 0;
}

.filter-modal-header {
  font-weight: 600;
  font-size: 25px;
  line-height: 31px;
  color: var(--bs-dark);
}

.filter-button {
  padding-left: 6px;
  padding-top: 4px;
}

.filter-button-active {
  border-color: var(--bs-secondary);
  --bs-btn-bg: var(--bs-secondary);
  --bs-btn-hover-bg: var(--bs-dark);
  --bs-btn-color: #FFF;
}

.filter-category-accordion {
  --bs-accordion-border-width: 0;
}

.applied-filter-button {
  border-radius: 2rem;
}
