.weather-inner-tabs {
  border-bottom: 0;

  .nav-link {
    color: #585858 !important;
    text-transform: capitalize !important;
    font-weight: 400 !important;
    background-color: transparent !important;

    &.active {
      color: var(--bs-primary) !important;
      font-weight: 600 !important;
      text-decoration-line: underline;
      text-underline-offset: 4px;
    }
  }
}

.weather-toggle-button {
  --bs-btn-active-bg: var(--bs-primary);
  --bs-btn-active-border-color: var(--bs-primary);
  --bs-btn-bg: #C1C1C1;
  --bs-btn-border-color: #C1C1C1;
  font-weight: 600;
  display: flex;
  align-items: center;
  --bs-btn-padding-y: 0;
}

.weather-download-button {
  padding: 0;
  color: white;
  font-size: 20px;
  line-height: 28px;
  background-color: var(--bs-primary);
  border: 0;
}

.weather-description {
  position: relative;
  height: 100%;

  div {
    position: absolute;
    padding: 1rem;
    height: 100%;
    overflow-y: auto;
  }
}
