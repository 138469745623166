.action-button-container {
  display: inline-block;
  margin: .25rem;
}

.action-button {
  border-color: #C1C1C1;
  border-radius: 1.01875px;
  --bs-btn-hover-bg: var(--bs-secondary);
  --bs-spinner-width: 1em;
  --bs-spinner-height: 1em;
}

.action-button:disabled {
  border-color: #C1C1C1;
  color: #C1C1C1;
}

.action-spinner {
  --bs-spinner-width: 1em;
  --bs-spinner-height: 1em;
}
