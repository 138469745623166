.seasonal-forecast-image {
  display: flex;
  justify-content: center;
  cursor: pointer;
  max-height: 70vh;
  max-width: 100%;
  height: auto;
  margin-top: 4rem;

  img {
    max-width: 100%;
  }
}

.no-image {
  visibility: hidden;
}
