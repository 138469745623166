.email-list {
  font-size: .75rem;
  padding: .05rem .5rem;
  margin: .3rem 0rem;
  border-radius: 2px;
  display: table;
}

.listed-email {
  color: var(--bs-dark);
  background-color: #D9D9D9;
}

.undefined-email {
  color: white;
  background-color: var(--bs-danger);
}
