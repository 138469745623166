.nav-link:not(.active) {
    font-weight: 600;
}

.nav-link.active .nav-pills {
    font-weight: 600;
}

.bottom-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 3.3125rem;
    background: white;
    box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.1);
}
