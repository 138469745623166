.toggle-box {
  position: relative;
  display: inline-block;
  min-width: 40px;
  height: 20px;
  border-radius: 2px;
}

.toggle-box input {
  opacity: 0;
  width: 0;
  height: 0;
  border-radius: 8px;
}

.toggle-switch {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 2px;
  background-color: var(--bs-danger);
  -webkit-transition: .2s;
  transition: .2s;
}

.toggle-switch:before {
  position: absolute;
  left: 2px;
  right: 2px;
  top: 2px;
  bottom: 2px;
  content: "";
  height: 16px;
  width: 16px;
  border-radius: 1px;
  background-color: white;
  -webkit-transition: .2s;
  transition: .2s;
}

input:checked + .toggle-switch {
  background-color: var(--bs-success);
}

input:focus + .toggle-switch {
  box-shadow: 0 0 1px var(--bs-success);
}

input:checked + .toggle-switch:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}
