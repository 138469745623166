.right-sidebar-toggle {
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  color: #598CBB;
  text-decoration: none;
}

.control-panel-item {
  --bs-accordion-border-width: 0;
  margin-bottom: .25em;

  .accordion-button {
    color: #212F58;
    background-color: #ECECEC;
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
  }

  .accordion-button::after {
    background-image: none;
    width: 0;
  }

  .accordion-button:not(.collapsed)::after {
    background-image: none;
    width: 0;
  }

  .accordion-button:focus {
    border-color: transparent;
    box-shadow: unset;
  }
}

.clear-filters {
  color: #26548A;
  font-weight: 600;
  text-decoration: none;
}
