.seasonal-comparator-period-datepicker {
  .react-datepicker {
    border-width: 0;
    border-radius: 4px;
  }
  
  .react-datepicker__month-text {
    padding: 0.5rem 0.2rem;
    color: #212F58;
    font-weight: bold;
  }
  
  .react-datepicker__month-text--in-range {
    background-color: #E9E9E9;
  }
  
  .react-datepicker__month-text--range-start {
    background-color: #598CBB !important;
    color: white !important;
  }
  
  .react-datepicker__month-text--range-end {
    background-color: #598CBB !important;
    color: white !important;
  }
  
  .react-datepicker__navigation {
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    border-color: #9D9D9D;
    margin: 0.5rem;
  }
  
  .react-datepicker__navigation-icon {
    top: 3px;
    margin: 1px;
  }
  
  .react-datepicker__navigation-icon--previous {
    right: 1px;
  }
  
  .react-datepicker__navigation-icon--next {
    left: 1px;
  }
  
  .react-datepicker__header {
    background-color: white;
    border-bottom: 0;
    margin: 0.4rem 0;
    color: #434343;
  }
}
