.attached-file {
  font-style: italic;
  color: #585858;

  .btn {
    --bs-btn-color: #585858;
    --bs-btn-bg: transparent;
    --bs-btn-border-color: transparent;
    --bs-btn-hover-color: #C1C1C1;
    --bs-btn-hover-bg: transparent;
    --bs-btn-hover-border-color: transparent;
    --bs-btn-active-color: #585858;
    --bs-btn-active-bg: transparent;
    --bs-btn-active-border-color: transparent;
    --bs-btn-disabled-color: #585858;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: transparent;
  }
}
