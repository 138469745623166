.admin-table-header {
  line-height: 18px;
  font-size: 0.875em;
  font-weight: 700;
  padding-bottom: 15px;
  text-transform: uppercase;
  padding-left: 20px;
  padding-right: 40px;
}

.admin-table-row {
  overflow-wrap: anywhere;
  .accordion-button {
    &:not(.collapsed) {
      color: var(--bs-accordion-btn-color);
      background-color: #f5f5f5;
      box-shadow: 0px 0px;
    }
  }

  .accordion-body {
    background-color: #f5f5f5;
    margin-top: 10px;
  }
}

.admin-table-main {
  padding: 0px;
}

.admin-table-hr {
  border: 1px solid #f5f5f5;
  margin: 5px;
}

h5 {
  color: #212f58;
  font-weight: bold;
  padding-bottom: 2px;
  border-bottom: 2px solid white;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 20px;
}

.admin-info-set {
  margin-bottom: 16px;

  .admin-info {
    font-size: 18px;
    height: fit-content;
    margin-bottom: 6px;
    margin-left: 10px;

    .info-header {
      color: #598cbb;
      font-weight: 600;
      margin-right: 4px;
    }

    .contract-info-header {
      color: #598cbb;
      font-weight: 600;
      margin-right: 4px;
    }
  }
}

.box {
  display: inline-block;
  width: fit-content;
  padding: 0 8px;
  margin: .2rem;
  font-size: 18px;
  border-radius: 1.63px;
  white-space: nowrap;
}

.outline-box {
  @extend .box;
  border-style: solid;
  border-width: 0.63px;
  border-color: #D9D9D9;
}

.product-description, .user-description {
  font-size: 18px;
}

.product-name, .user-name {
  font-weight: 600;
}

.unavailable-product, .unavailable-user {
  color: #c1c1c1;
}
