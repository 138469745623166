.synoptic-chart-container {
  background-color: rgb(244, 244, 244);
}

.synoptic-charts-dropdown-selector-toggle {
  background-color: rgb(244, 244, 244) !important;
  border-color: #C1C1C1 !important;
  color: var(--bs-primary) !important;

  &:hover {
    background-color: rgb(222, 222, 222);
    border-color: #C1C1C1;
    color: var(--bs-primary);
  }

  &::after {
    margin-left: 2rem;
  }
}

.synoptic-chart-dropdown-item {
  text-decoration: none;
  font-weight: bold;
  color: #212F58;

  &:hover, &:active {
    color: #212F58 !important;
    background-color: var(--bs-dropdown-link-hover-bg) !important;
  }
}
