.anomaly-title {
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  color: var(--bs-dark);
}

.anomaly-map {
  display: inline-block;
  position: relative;

  .map-tropics {
    position: absolute;
    left: 4.8%;
    right: 8.4%;
    top: 42.5%;
    bottom: 35.5%;
    border: 2px dashed var(--bs-primary);
  }

  @keyframes glow {
    0% {
      box-shadow: 0 0 5px var(--bs-dark);
      text-shadow: 0 0;
    }
    100% {
      box-shadow: 0 0 15px var(--bs-dark);
      text-shadow: 0 0 2px var(--bs-dark);
    }
  }

  .map-annotation {
    position: absolute;
    font-size: 14px;
    font-weight: 600;
    color: var(--bs-dark);
    border-radius: 1.63px 0px 0px 0px;
    background-color: #fff;
    padding: 2px 5px;
    text-decoration: none;

    animation-name: glow;
    animation-duration: 1.0s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;

    &:hover {
      color: #fff;
      background-color: var(--bs-primary);
    }
  }
}

.map-legend-label {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  text-decoration: none;
  color: var(--bs-dark);
}
