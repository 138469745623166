.counter-box {
  height: 110px;
  min-width: 10rem;
  background-color: #f9f9f9;
  margin-bottom: 56px;
  display: flex;
  flex-direction: column;
  border-bottom: 4px solid;
  cursor: pointer;

  .box-title {
    color: var(--bs-dark);
    font-size: 14px;
    justify-self: flex-end;
    margin: 0 12px;
  }

  .hstack {
    .main-value {
      line-height: 45px;
      font-size: 36px;
      margin-right: 12px;
      color: var(--bs-dark);
    }
    .sub-values {
      height: 15px;
      font-size: 12px;
      margin-right: 12px;
      color: var(--bs-dark);
    }
  }
}

.active-company {
  border-bottom-color: var(--bs-success);
}

.test-company {
  border-bottom-color: #D9D9D9;
}

.contract-expires-in-30-days {
  border-bottom-color: #F18449;
}

.contract-expires-in-60-days {
  border-bottom-color: var(--bs-warning);
}

.consulting-company {
  border-bottom-color: var(--bs-secondary);
}

.meteorology-company {
  border-bottom-color: var(--bs-secondary);
}

