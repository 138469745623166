.instruction-panel {
  width: 90%;
  background-color: white;
}

.instruction-panel-title {
  font-weight: 600;
  color: #598CBB;
  font-size: larger;
  border-bottom: 2px solid #598CBB;
}

.instruction-panel-content-title {
  font-weight: 600;
  font-size: 18px;
  color: #212F58;
}

.instruction-panel-content-block {
  font-size: 16px;
  color: #212F58;
}
