.sorting-arrow, .sorting-arrow:hover, .sorting-arrow:active, .sorting-arrow:focus {
  padding: 0;
  --bs-btn-bg: #ffffff00;
  --bs-btn-color: #000;
  --bs-btn-hover-bg: #ffffff00;
  --bs-btn-hover-color: #000;
  --bs-btn-active-bg: #ffffff00;
  --bs-btn-active-color: #000;
  --bs-btn-focus-bg: #ffffff00;
  --bs-btn-focus-color: #000;
  --bs-btn-line-height: 0;
  border-style: none;
  width: 2rem;
}

.sorting-arrow svg {
  height: 0.5rem;
}
