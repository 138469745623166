.card-element {
  padding: 0 48px;
}

.comparison-text {
  padding: 0 24px;
}

.letter-box {
  display: flex;
  justify-content: center;
  margin: 4px;
  background-color: #C1C1C1;
  padding: 4px;
  border-radius: 5px;
}