.flux-personalized-block {
  padding: .5rem;
  margin-bottom: 1rem;
  border: 1px solid #98D6F4;
  border-top-width: 1rem;
  border-radius: .1rem;
  color: #585858;
  font-size: 0.625rem;
  font-weight: 400;

  .block-title {
    font-size: 0.875rem;
    font-weight: 600; 
  }

  .block-model {
    text-transform: capitalize;
  }
}