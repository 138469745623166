.mail-read-badge {
  position: absolute;
  background-color: var(--bs-dark);
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: .7rem;
  text-align: center;
  padding-top: 3px;
  margin-top: 5px;
  margin-left: -7px;
}

.mailer-report-company {
  .accordion-button {
    background-color: #ECECEC;
  }

  .accordion-button {
    &:not(.collapsed) {
      background-color: #f4f4f4 !important;
      box-shadow: 0px 0px;
    }
  }

  .accordion-body {
    margin-top: 1px !important;
    background-color: #f4f4f4 !important;
  }
}
