.flux-personalized-row {
  border: 0;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  margin: .625rem 0;

  .accordion-button {
    &:not(.collapsed) {
      background-color: #f4f4f4;
    }

    border: 0;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    box-shadow: 0px 0px;
    color: black;
  }

  .accordion-body {
    border: 0 solid white;
    border-top-width: 2px;
    background-color: #f4f4f4;
  }
}

.flux-personalized-details {
  font-weight: 600;
}

.flux-personalized-study {
  font-size: 1.25rem;
  margin-bottom: .25rem;
}

.flux-personalized-status {
  font-size: .75rem;
}

.flux-personalized-button {
  display: block;
  line-height: 0;
  padding: 4px;
  --bs-btn-disabled-color: #D9D9D9;
  --bs-btn-disabled-bg: #F4F4F4;
  --bs-btn-disabled-border-color: white;
}
