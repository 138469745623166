.auth-background {
  background-color: var(--bs-dark);
  min-height: 100vh;
}

.auth-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6rem;
  width: 283px;
}

.auth-card {
  box-shadow: 0px 0px 10px 3px rgba(255, 255, 255, 0.25);
  border-radius: 2px;
  margin: 7em auto;
}

.auth-card-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: var(--bs-dark);
}

.auth-form-label {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #585858;
}

.auth-form-control {
  border: 1px solid #D9D9D9;
  border-radius: 1.63px;
}
