.chart-selector {
  background-color: #F4F4F4;
  box-shadow: 0 0 8px #888888;

  & > * {
    text-align: start;
    width: 100%;
    color: #212F58;
    display: block;
    text-decoration: none;
  }
}