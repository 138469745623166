.observed-data-display {
  height: 85vh;
  align-items: center;
}

.observed-data-no-images {
  text-align: center;
  font-size: 1.5rem;
  color: #585858;
  margin-top: 3rem;
  margin-bottom: 3rem;
}
