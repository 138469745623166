.success-title {
  background-color: var(--bs-success);
  height: 53px;
}

.success-body {
  text-align: center;
  color: var(--bs-primary);
}

.success-button {
  padding: 5 17;
  width: fit-content;
}
