.block-datepicker {
  position: relative;

  .form-control {
    padding-left: 2.15rem;
    border-radius: 1.63px;
  }

  .icon {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker-popper {
    z-index: 3;
  }
}
