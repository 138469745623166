.calendar-icon {
  cursor: pointer;
  width: 30px;
  height: 30px;
  border: 1px solid #C1C1C1;
  border-radius: 3px;
  padding-left: 0.32rem;
  padding-top: .15rem;
  background-color: white;
  color: var(--bs-dark);
}

.calendar-active {
  border-color: var(--bs-secondary);
  background-color: var(--bs-secondary);
  color: white;
}

.invoice-datepicker {
  cursor: pointer;
  border: none;
}
