.video-grid-row-date {
  color: #C1C1C1;
  font-weight: 700;
}
  
.video-grid-row-title {
  color: var(--bs-primary);
  font-size: 1.1rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.video-grid-row-description {
  color: #212f58;
}