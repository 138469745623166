.block-form {
  background: #FFF;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
  padding: 1rem;

  .block-form-title {
    color: #212F58;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .secondary-button {
    margin-left: auto;
    color: #598CBB;
  }
}
