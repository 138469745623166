.search-bar {
  position: relative;
}

.search-bar .form-control {
  padding-left: 2.375rem;
  border-radius: 1.63px;
}

.search-bar .search-icon {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #C1C1C1;
}
