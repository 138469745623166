.video-list-grid-toggle {
  cursor: pointer;

  &.enabled {
    color: var(--bs-primary);
  }

  &.disabled {
    color: gray;
  }
}