.file-list-header {
  border-bottom: 1px solid lightgray;
  font-size: 1.5rem;
  & a {
    text-decoration: none !important;
  }
}

.file-list-body {
  overflow-y: auto;
}
