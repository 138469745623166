.indicator {
  height: 20px;
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  font-size: 16;
  color: #FFFFFF;
}

.indicator-active {
  background-color: var(--bs-success);
}

.indicator-inactive {
  background-color: var(--bs-danger);
}
