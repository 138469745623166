$track-color: #26548A;
$inner-track-color: var(--bs-secondary);

.forecast-range-control {
  display: flex;
}

.horizontal-slider, .pentad-slider {
  width: 90%;
  margin-top: 15px;
  margin-left: 5%;
  margin-right: 5%;
  height: 8px;
}

.pentad-slider {
  .example-thumb-0 {
    .thumb-value {
      left: -15px;
    }
  }

  .example-thumb-1 {
    .thumb-value {
      left: 0px;
    }
  }

  .example-track-1 {
    background: $inner-track-color;
  }
}

.example-thumb {
  top: -7.5px;
  padding-bottom: 22.5px;
  height: 25px;
  width: 25px;
  cursor: pointer;
  position: absolute;
  z-index: 100;
  background: #ffffff;
  border: 3px solid $track-color;
  border-radius: 100%;
  display: block;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
}

.thumb-value {
  position: absolute;
  top: -25px;
  font-size: 14px;
  left: -5px;
}

.example-track {
  top: 0;
  bottom: 0;
  background: $track-color;
  border-radius: 999px;
}

.slider-control-button {
  color: $track-color;
}