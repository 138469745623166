.body-outlet {
  padding-top: 3rem;
}

.body-outlet-admin {
  padding-top: 8rem;
}

.icon-button {
  min-width: 31px;
  width: 31px;
  height: 31px;
  border-color: #C1C1C1;
  border-radius: 1.01875px;
  --bs-btn-bg: #FFF;
  --bs-btn-color: var(--bs-dark);
}
