.meteorology-fields-display {
  height: 85vh;
  overflow: scroll;
}

.images-not-found {
  text-align: center;
  margin-top: 3rem;
  font-size: 1.5rem;
  color: #585858;
}

#met-fields-top-scrollbar {
  text-align: center;
  overflow: scroll;
  overflow-y: hidden;
}
