.home-tab-container .tab-pane {
  max-height: 70vh;
  overflow-y: auto;
}

.status-tab {
  th {
    color: var(--bs-gray);
  }

  thead {
    border-bottom: 1px solid var(--bs-gray);
  }

  td.model-name {
    color: var(--bs-dark);
    font-weight: bold;
  }

  td.last-update {
    text-align: center;
  }

  td.status {
    color: var(--bs-white);
    font-weight: bold;
    padding: .5em;

    div {
      text-align: center;
      border-radius: 1.7px;
    }
  }
}

.warnings-badge {
  position: absolute;
  top: 0;
  left: 100%;
  height: 30px;
  width: 30px;
  font-size: .9rem;
  background-color: var(--bs-secondary);
  border-radius: var(--bs-border-radius-pill);
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}
