.show-instruction {
  position: relative;
  border-radius: 0.125rem;
  border: 1px dashed #585858;

  .instruction-number {
    max-width: 24px;
    position: absolute;
    bottom: -12px;
    left: 50%;
    margin-top: -24px;
    margin-left: -12px;
  }
}