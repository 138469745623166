.btn-control-panel {
  font-weight: 600;
  color: #212F58;
  font-size: 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.btn-control-panel:hover {
  color: #212F58;
}

.btn-control-panel-active {
  background: #598CBB;
  color: #FFFFFF;
}

.btn-control-panel-active:hover {
  background: #598CBB;
  color: #FFFFFF;
}

.control-panel-columns {
  grid-template-columns: 1fr 1fr;
}

.btn-preference {
  border: 0;
  border-radius: 2px;
	--bs-btn-color: var(--bs-dark);
	--bs-btn-bg: transparent;
  --bs-btn-disabled-color: #585858;
  --bs-btn-disabled-bg: transparent;
}
