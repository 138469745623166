.study-status-tag {
  min-width: 5.4375rem;
  font-size: .75rem;
  padding-top: .1rem;
  padding-bottom: .1rem;
  text-align: center;
  color: #212F58;

  &.study-status-red {
    background-color: #CA5959;
    color: white;
  }

  &.study-status-green {
    background-color: #A7CD78;
  }

  &.study-status-gray {
    background-color: #D9D9D9;
  }
}

.study-status-info {
  color: #585858;
  font-size: 0.5625rem;
  margin-left: .5rem;
  margin-right: 1rem;
}
