%mass-mail-header {
  padding-bottom: 20px;
}

.mass-email-header {
  @extend %mass-mail-header;
  border-bottom: 2px #C1C1C1;
}

.mass-email-header-danger {
  @extend %mass-mail-header;
  background-color: #CA5959;
  color: white;
  border-bottom: 2px white;
}

.no-products-error-message {
  color: #CA5959;
}

.tabbed-page:has(.mass-email-header-danger) {
  .nav-link.active {
    background-color: #CA5959 !important;
    color: white !important;
  }
}
