.instruction-color-1 {
  color: #fb8500;
}

.instruction-color-2 {
  color: #e63946;
}

.instruction-color-3 {
  color: var(--bs-primary);
}

.instruction-color-4 {
  color: #008000;
}

.template-instructions {
  h3 {
    font-weight: 600;
  }

  h4 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-top: 1.25rem;
    margin-bottom: 1rem;
  }

  ul li div {
    padding-left: 4rem;
  }
}

.instruction-subtitle {
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: .5rem;
}
