.period-pills {
  width: 90%;
  margin: 0 5%;
  border-radius: 20px;
  text-align: center;
}

.mode-switcher {
  width: 100%;
  display: flex;
  background-color: #F4F4F4;
  padding: 3px;
  margin-bottom: 8px;
  border-radius: 80px;
}

%mode-selector {
  margin: 4px 2px;
  border-radius: 25px;
}

.mode-selector {
  @extend %mode-selector;
}

.mode-selector-current {
  @extend %mode-selector;
  background-color: white;
}

.button-link {
  text-decoration: none;
  color: black;
}

.button-link:hover {
  color: black;
}

.react-datepicker-wrapper,
.period-selector {
  width: 180px;
}
