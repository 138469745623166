.mailerTitle {
    color: #26548a;
    font-weight: 600;
}

.nav-link:not(.active) {
    font-weight: 600;
}

.nav-link.active .nav-pills {
    font-weight: 600;
}