.flux-sub-table {
  .modal-title {
    width: 100%;
  }

  .modal-content {
    width: 170%;
    right: 40%;
  }

  .header {
    color: #212f58;

    .model-name {
      font-size: 2rem;
      font-weight: bold;
      margin-left: 1rem;
      margin-right: 2rem;
    }

    .revision {
      font-size: 1.3rem;
      margin-right: 2rem;
    }

    .period {
      font-size: 1.5rem;
    }
  }
}

.flux-sub-table-arrow-container {
  width: 7%;
}

.flux-sub-table-arrow {
  font-size: 1.75rem;
  color: #212f58;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
}

.flux-sub-table-spinner-container {
  height: 82vh;
}