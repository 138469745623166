.nav-item {
  a {
    text-decoration: none;
    color: #585858;
  }
}

.active-item {
  a {
    color: #212f58 !important;
    text-decoration: underline !important;
    font-weight: bold !important;
  }
}
