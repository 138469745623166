.invoice-header {
  margin: 0px;
  font-size: 1.25rem;
  font-weight: 600;
  color: black;
}

.invoice-info-title {
  font-weight: 600;
  margin-right: .5rem;
}

.invoice-tag {
  font-size: .75rem;
  padding: .05rem .5rem;
  margin-right: .75rem;
  border-radius: 2px;
}

.tag-green {
  color: var(--bs-dark);
  background-color: var(--bs-success);
}

.tag-red {
  color: white;
  background-color: var(--bs-danger);
}
