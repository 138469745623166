.btn-toggle {
  padding: .25rem .5rem;
  font-weight: 600;
  color: var(--bs-white);
  background-color: transparent;
}

.btn-toggle:hover,
.btn-toggle:focus,
.btn-toggle[aria-expanded="true"] {
  color: var(--bs-dark);
  background-color: #FFFFFF;
}

.btn-toggle-arrow::before {
  width: 1.25em;
  line-height: 0;
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%28255,255,255,1%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
  transition: transform .35s ease;
  transform-origin: .5em 50%;
}

.btn-toggle-arrow:hover::before,
.btn-toggle-arrow:focus::before {
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%2833,87,88,1%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
}

.btn-toggle[aria-expanded="true"]::before {
  transform: rotate(90deg);
}

.btn-toggle-nav a {
  padding-bottom: .1875rem;
  padding-top: .1875rem;
  padding-left: 1.75rem;
  padding-right: .5rem;
  margin-top: .125rem;
  color: var(--bs-white);
  width: 100%;
  border-radius: 0 !important;
}
.btn-toggle-nav a:hover,
.btn-toggle-nav a:focus {
  background-color: var(--bs-secondary);
  font-weight: 600;
}

.link-body-emphasis {
  color: var(--bs-white) !important;

  &:hover, &:focus {
    color: var(--bs-white) !important;
  }
}

.sidebar-container {
  background-color: var(--bs-dark);
  color: #FFFFFF;
  height: 100%;
  flex: 0 0 auto;
  width: max(12%, 9rem);
  transition: width 0.3s;
  will-change: width;
  z-index: 100;
}

.sidebar-container.collapsed {
  width: 3.5rem;
}

.no-arrow {
  padding-left: 1.7rem;
}

.btn-collapse {
  font-size: 1.25rem;
  padding-left: .75rem;
  padding-right: .75rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  background-color: transparent;
  border-color: transparent;
  color: #FFFFFF;
}

.btn-collapse:hover {
  border-radius: 50%;
  background-color: #FFFFFF;
  color: var(--bs-primary);
  box-shadow: 0 0 1px 0px #FFFFFF inset, 0 0 1px 0px #FFFFFF;
}

.version-number {
  font-size: .75rem;
  align-self: flex-end;
  white-space: nowrap;
  overflow: hidden;
}

.btn-subitems {
  font-size: 1em !important;
}
