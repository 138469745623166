.invoice-checkbox {
  display: block;
  position: relative;
  padding-left: 20px;
  height: 20px;
  width: 20px;
  border-radius: 1px;
  cursor: pointer;
  font-size: 2rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-red {
  background-color: var(--bs-danger);
}

.checkbox-yellow {
  background-color: var(--bs-warning);
}

.checkbox-green {
  background-color: var(--bs-success);
}

/* Hide the browser's default checkbox */
.invoice-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.invoice-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 1px;
}

.invoice-checkbox:hover input ~ .invoice-checkmark {
  background-color: rgba(0, 0, 0, 0.2);
}

.invoice-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.invoice-checkbox input:checked ~ .invoice-checkmark:after {
  display: block;
}

.invoice-checkbox .invoice-checkmark:after {
  left: 7px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
