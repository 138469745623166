$track-color: #26548A;
$inner-track-color: var(--bs-secondary);

.seasonal-forecast-period-control {
  display: flex;
  justify-content: center;

  &.disabled {
    .seasonal-forecast-slider-control-button {
      color: #C1C1C1 !important;
      border-color: #C1C1C1 !important;
    }

    .seasonal-forecast-track {
      background-color: #C1C1C1 !important;
    }

    .seasonal-forecast-thumb, .seasonal-forecast-thumb-label, .thumb-value {
      visibility: hidden;
    }
  }
}

.seasonal-forecast-slider {
  width: 50%;
}

.seasonal-forecast-track {
  cursor: pointer;
  top: 0;
  bottom: 0;
  background: #26548A;
  border-radius: 999px;
}

.seasonal-forecast-thumb {
  top: -7.5px;
  padding-bottom: 22.5px;
  height: 25px;
  width: 25px;
  cursor: pointer;
  position: absolute;
  z-index: 0 !important;
  background: #ffffff;
  border: 3px solid $track-color;
  border-radius: 100%;
  display: block;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
}

.seasonal-forecast-thumb-label {
  position: absolute;
  font-size: 14px;
  top: -25px;
  left: -38px;
  width: 100px;
  text-align: center;
}
