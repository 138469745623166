.simple-invoice-row-header {
  margin: 0px;
  font-weight: 600;
  color: black;
}

.simple-invoice-row-title {
  font-size: 1rem;
}

.simple-invoice-row-small-title {
  font-size: 0.75rem;
}

.simple-invoice-row-info-section {
  font-size: 0.9rem;
}

.simple-invoice-row-info-title {
  font-weight: 600;
  margin-right: .5rem;
}
