.period-selection{
  display: flex;
  margin: 4px 0;
  flex-direction: row;
}

%selector-style {
  flex-basis: 0;
  width: 30px;
  margin: 3px;
  padding: 4px 8px;
  color: white;
  border-radius: 4px;
  border: none;
}

.period-selector {
  @extend %selector-style;
  background-color: #C1C1C1;
}

.period-selector-current {
  @extend %selector-style;
  background-color: #598CBB;
}
