.sticky-table-col-row {
  thead th {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 0;
    z-index: 2; /* over the the columns */
    background-color: rgb(244, 244, 244);
  }

  tbody th {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: rgb(244, 244, 244);
  }
}
