.forecast-instruction-card {
  border: 0;
  border-radius: 0.10188rem;
  text-align: center;

  .card-header {
    background-color: white;
    border-color: var(--bs-secondary);
    color: var(--bs-secondary);
    font-weight: 600;
    line-height: 2rem;
  }
}

.navigation-instruction {
  text-align: left;

  .instruction-number {
    margin-left: 1rem;
    margin-right: .5rem;
  }

  .flex-row {
    margin-top: 1rem;
  }
}

.instruction-number {
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
  font-weight: 400;
  text-align: center;
  background-color: #D9D9D9;
  color: #585858;
}