.synoptic-charts-date-selector-container {
  & .react-datepicker-wrapper {
    width: 100%;
  }
}

.synoptic-charts-date-selector {
  border-color: #C1C1C1 !important;
  border-radius: 0;
  color: var(--bs-primary) !important;
}