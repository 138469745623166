.report-user-card {
  padding: 12px 10px 15px 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 3px;
  border-style: solid;
  border-color: white;
  background-color: #f4f4f4;
  color: #585858;
  font-size: 0.83769rem;
  font-weight: 400;

  h3 {
    color: var(--bs-dark);
    font-size: 1.17275rem;
    font-weight: 400;
  }

  h4 {
    color: var(--bs-dark);
    font-size: 0.83769rem;
    font-weight: 400;
  }

  .accordion {
    --bs-accordion-bg: #f4f4f4;

    .accordion-body {
      padding: 0px;
      background-color: #f4f4f4;
    }
  }

  .accordion-button {
    &:not(.collapsed) {
      box-shadow: 0px 0px;
    }

    background-color: #f4f4f4;
    padding: 3px 20px 3px 0px;
  }
}
