.automatic-pages-accordion-option {
  font-weight: 600;
  font-size: 18px;
  color: #212F58;
  padding: 10px 10px 10px 15px;
}

.automatic-page-clear-filter-button {
  color: var(--bs-primary);
  text-decoration: none;
  font-weight: 600;
}

.automatic-page-clear-filter-button:hover {
  color: var(--bs-primary);
}

.automatic-preference-button {
  font-family: 'Source Sans Pro', serif;
  font-weight: 600;
  font-size: 16px;
  color: #212F58;
}
