.dialog-success {
  .dialog-modal-header {
    background: #A7CD78;
  }

  .dialog-modal-btn {
    background-color: #A7CD78;
    border-color: #A7CD78;
  }

  .dialog-modal-btn:active {
    background-color: #A7CD78;
    border-color: #A7CD78;
    color: var(--bs-dark);
  }
}

.dialog-modal {
  --bs-modal-border-radius: 0;
  --bs-modal-width: 358px;
  --bs-border-color: #F5F5F5;
}

.dialog-modal-header {
  min-height: 53px;
  border-radius: 0;
}

.dialog-modal-body {
  color: var(--bs-dark);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
}

.dialog-modal-btn {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  border-radius: 0;
  color: var(--bs-dark);
}
