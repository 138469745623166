$theme-colors: (
  "primary":   #26548A,
  "secondary": #598CBB,
  "success":   #A7CD78,
  "info":      #98D6F4,
  "warning":   #FFE584,
  "danger":    #CA5959,
  "light":     #98D6F4,
  "dark":      #212F58
);

@import '@fontsource/source-sans-pro/400.css';
@import '@fontsource/source-sans-pro/600.css';
@import '@fontsource/source-sans-pro/700.css';
$font-family-sans-serif: "Source Sans Pro", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

@import "~bootstrap/scss/bootstrap";

h1 {
  color: var(--bs-primary);
  font-weight: 600;
  margin-bottom: 3rem;
}
