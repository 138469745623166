.new-upload-card {
  margin-top: 46px;
  margin-left: 53px;
  margin-right: 52px;
  border-radius: 1.63px;
  height: 100%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

  .card-header {
    margin-top: 10px;
    justify-content: center;
    background-color: white;
    border-color: var(--bs-secondary);
    color: var(--bs-secondary);
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 110.7%;
  }

  .card-body {
    padding: 0px;
    margin-bottom: 30px;
  }
}

.new-upload-left-column {
  padding-right: 37px;
}

.new-upload-right-column {
  margin-left: 39px;
  padding-right: 37px;
}

.new-upload-frame {
  margin-top: 42px;
  margin-left: 36px;
  margin-right: 37px;
}

.new-upload-warning-text {
  color: #212F58;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 100%;
}

.new-upload-instructions-text {
  color: #212F58;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  padding-left: 24px;
}

.new-upload-explanation-text {
  white-space: pre-line;
  font-size: 14px;
  margin-left: 39px;
  margin-bottom: 10px;

  p {
    margin-top: 24px;
  }
}
