.confirmation-dialog-modal {
  --bs-modal-border-radius: 0;
  --bs-modal-width: 358px;
  --bs-border-color: #F5F5F5;
}

.confirmation-dialog-modal-header {
  background: #D9D9D9;
  min-height: 53px;
  border-radius: 0;
}

.confirmation-dialog-modal-body {
  color: var(--bs-dark);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
}

.confirmation-dialog-modal-btn {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  border-radius: 0;
}

.btn-dismiss {
  background-color: #F5F5F5;
  border-color: #F5F5F5;
  color: #9E9E9E;
}
